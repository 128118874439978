<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="left">
            <div class="title big">
              REVOLUTIONIZING DIGITAL COMMERCE: AN IN-DEPTH LOOK INTO OUR AI-CENTRIC SHOP
            </div>
            <div class="desc">
              Unlocking the Future of Content Creation through Artificial Intelligence
            </div>
            <router-link class="button" to="/product-list">
              Explore Shop
            </router-link>
          </div>
          <div class="right">
            <div class="video-container">
              <video autoplay loop muted>
              <source src="./../images/video.mp4" type="video/mp4" />
            </video>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-categories" id="categories">
        <div class="wrapper" v-if="categories.length">
          <div class="flex">
            <div class="title big">
              EXPLORE OUR AI PRODUCT CATEGORIES
            </div>
            <router-link class="desc link" to="/product-list">SHOP</router-link>
          </div>
          <carousel-3d :width="424" :height="424" :clickable="false" :controlsVisible="true">
            <slide  v-for="(item, i) in categories" :index="i" :key="i">
              <img :src="imgDomain + item.image_url" class="img" @click="chooseCategory(item)"/>
              <div class="title">{{item.title}}</div>
            </slide>
          </carousel-3d>
        </div>
      </div>
      <div class="section features-section" id="features">
        <div class="wrapper">
          <img src="./../images/features.svg" class="img features-img"/>
          <ul class="list">
            <li class="item">
              <div class="title">01</div>
              <div class="title">LEGALIZED CONTENT</div>
            </li>
            <li class="item">
              <div class="title">02</div>
              <div class="title">WIDE ASSORTMENT</div>
            </li>
            <li class="item">
              <div class="title">03</div>
              <div class="title">QUICK REGISTRATION</div>
            </li>
            <li class="item">
              <div class="title">04</div>
              <div class="title">GLOBAL AUDIENCE</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="section section-contact" id="contact">
        <div class="wrapper">
          <div class="title small">DON`T BE SHY, EMAIL US!</div>
          <div class="title big">{{ $parent.settings.support_email }}</div>
          <div class="links">
            <a href="#" class="title big link">INSTAGRAM</a>
            <a href="#" class="title big link">FACEBOOK</a>
          </div>
        </div>
      </div>
      <div class="section section-how-it-works" id="how-it-works">
        <div class="wrapper">
          <div class="title big">HOW IT WORKS</div>
          <div class="list">
            <div class="item">
              <div class="title big">01</div>
              <div class="title small">SIGN UP</div>
            </div>
            <div class="item">
              <div class="title big">02</div>
              <div class="title small">BROWSE CATEGORIES</div>
            </div>
            <div class="item">
              <div class="title big">03</div>
              <div class="title small">buy AI pictures or text</div>
            </div>
            <div class="item">
              <div class="title big">04</div>
              <div class="title small">DOWNLOAD AND IMPLEMENT</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'Home',
  props: ['currency'],
  components: {
    Carousel3d,
    Slide
  },
  data: function() {
    return {
      imgDomain: '',
      categories: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload;
      })
      .catch(() => {
         
      })
  },
  methods: {
    chooseCategory(item) {
      this.$emit('chooseCategory', item)
    },
  }
}
</script>