import Vue from 'vue'
import Vuex from 'vuex'
import tools from "@/store/modules/tools";

Vue.use(Vuex)

// Define your state
const state = {
    // Other state properties go here
}

// Define your getters
const getters = {
    // Other getters go here
}

// Define your mutations
const mutations = {

    // Other mutations go here
}

// Define your actions
const actions = {

    // Other actions go here
}

// Create a new Vuex store instance
const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        tools
    }
})

export default store