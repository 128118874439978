import axios from "@/axios";

export default {
    async fetchTools({commit}) {
        try {
            const {data: {payload}} = await axios.get(process.env.VUE_APP_API + 'tools')
            commit('STORE_TOOLS', payload)
        } catch (error) {
            console.error('An error occurred:', error)
        }
    }
}