<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <ul class="nav">
            <li class="nav__item" @click="scrollToSection('features')">
              <div class="desc">OUR FEATURES</div>
            </li>
            <li class="nav__item" @click="scrollToSection('categories')">
              <div class="desc">CATEGORIES</div>
            </li>
            <li class="nav__item" @click="scrollToSection('how-it-works')">
              <div class="desc">HOW IT WORKS</div>
            </li>
            <li class="nav__item" @click="scrollToSection('contact')">
              <div class="desc">CONTACT US</div>
            </li>
          </ul>
          <ul class="nav" v-if="$parent.textPageList.length">
            <template  v-for="item in $parent.textPageList">
              <li class="nav__item nav__item-text-pages" :key="item.id">
                <div @click="$parent.goToPage(item.id)" class="desc">
                  {{item.title}}
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="center">
          <router-link to="/" class="logo">
            <img src="./../images/logo.svg" class="img"/>
          </router-link>
        </div>
        <div class="right">
          <div class="text">
            <div class="desc link">
              {{ $parent.settings.company_name }}
            </div>
            <div class="desc link">
              <img src="./../images/location.svg" class="img"/>
              {{ $parent.settings.requisites }}
            </div>
            <div class="desc link">
              <img src="./../images/envelope.svg" class="img"/>
              {{ $parent.settings.support_email }}
            </div>
            <ul class="list">
              <li class="item">
                <img src="./../images/mc_symbol.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../images/visa.svg" class="img"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-section">
        <div class="wrapper">
          <div class="left">
            
          </div>
          <div class="right">
            <div class="desc">{{ $parent.settings.copyright }}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 1)
      this.$emit('setActiveModel', model);
    }
	},
	mounted() {
    
  }
}
</script>
