<template>
  <div class="modal contact-modal ready-modal">
    <div class="overlay"  @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeReadyModal" src="./../images/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields" v-if="!$parent.successMessage">
              <div class="title">Are you sure?</div>
              <div class="price title">TOTAL: {{modifiedPrice}} {{$parent.currency}}</div>
              <button class="button" @click="buyProduct">
                <span>Buy</span>
              </button>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{$parent.error}}</div>
              </transition>
            </div>
            <transition name="fade">
              <div class="form-fields" v-if="$parent.successMessage">
                <div class="title error-desc green">{{$parent.successMessage}}</div>
                <button class="button" @click="$parent.closeReadyModal">
                  <span>Ok</span>
                </button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  computed: {
		modifiedPrice: function() {
      let price;
			if (this.$parent.readyPromt) {
				price = this.$parent.productPageItem.price;
			} else if (this.$parent.readyPack) {
				price = this.$parent.activePack.price;
			} 
      return price;
		}
	},
  methods: {
    buyProduct() {
      if (this.$parent.readyPromt) {
        this.$parent.buyProduct();
      } else if (this.$parent.readyPack) {
        this.$parent.buyPack();
      }
    }
  }
}
</script>