<template>
  <div class="modal sign-modal">
    <div class="overlay"  @click="$emit('closeSignUpModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignUpModal')" src="./../images/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class='form-fields register-content'>
              <div class="form-fields-wrapper">
                <div class="tabs">
                  <div class="title small">SIGN UP</div>
                  <div class="title small gray" @click="$emit('openSignInModal')">SIGN IN</div>
                </div>
                <div class="desc">Create your free account</div>
                <label>
                  <div class="desc">*Имя</div>
                  <input type="text" placeholder="Name" v-model="name"/>
                </label>
                <label>
                  <div class="desc">*Фамилия</div>
                  <input type="text" placeholder="Surname" v-model="surname"/>
                </label>
                <label>
                  <div class="desc">*Электронная почта</div>
                  <input type="email" placeholder="E-mail" v-model="email"/>
                </label>
                <label>
                  <div class="desc">*Телефон</div>
                  <input type="number" @input="allowOnlyNumbers" placeholder="Phone" v-model="phone"/>
                </label>
                <label>
                  <div class="desc">*Пароль</div>
                  <input type="password" placeholder="Password" v-model="pass"/>
                </label>
                <label>
                  <div class="desc">*Подтвердите пароль</div>
                  <input type="password" placeholder="Confirm Password" v-model="passConfirm"/>
                </label>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms"/>
                        <div class="checkbox"></div>
                        <span class="title">I agree with </span>
                        <a v-if="textPageList" @click="$parent.goToPrivacy()" class="title"> {{ textPageList[1].title }}</a>
                        <span class="title"> and </span>
                        <a v-if="textPageList" @click="$parent.goToTerms()" class="title"> {{ textPageList[0].title }}</a>
                      </div>
                    </label>
                  </div>
                  <button :class="['button blue', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                    <span>Continue</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignUpModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false,
      textPageList: ''
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'static-pages')
    .then((res) => {
      this.textPageList = res.data.payload
    })
    .catch(() => {
        
    })
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    allowOnlyNumbers(e){
      var regex = RegExp(/[0-9]+/g);
      const test_result = regex.test(e.target.value);

      if (test_result){
        e.target.defaultValue = e.target.value;
      } else{
        e.target.value = e.target.defaultValue;
      }
    },
    closeSignUpModal() {
      this.$emit('closeSignInModal')
    },
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    }
  }
}
</script>