<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title big">SHOP</div>
        </div>
      </section>
      <section class="section product-list-section2">
        <div class="input-container search">
          <img src='./../images/glass.svg' class="img"/>
          <input type="text" placeholder="Search" v-model="search"/>
          <button class="button" @click="filterProducts">
            Search
          </button>
        </div>
        <div class="wrapper">
          <div class="top">
            <div class="select-container" v-if="toolOptions.length">
              <div class="title small">TOOL</div>
              <label class="product-list-checkbox" v-for="item in toolOptions" :key="item.id">
                <input type="checkbox" v-model="tool" :id="item.id" :value="item" @change="filterProducts()" />
                <div class="desc">{{item.title}}</div>
              </label>
            </div>
            <template v-if="categoryOptions.length">
            <div v-for="parent in categoryOptions" class="select-container" :key="parent.id">
              <div class="title small">
                CATEGORY
                <div class="title gray">({{ parent.title.toUpperCase() }})</div>
              </div>
              <label class="product-list-checkbox" v-for="item in parent.child" :key="item.id">
                <input type="checkbox" v-model="category" :id="item.name" :value="item" @change="filterProducts()" />
                <div class="desc">{{item.title}}</div>
              </label>
            </div>
            </template>
          </div>
          <transition name="fade">
            <div class="list products" v-if="productList">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard @openBuyModal="openBuyModal" :item="item" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" :categories="categoryOptions"/>
              </div>
              <div class="title no-products" v-if="!productList.length">
                No products!
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ProductList',
  props: ['currency'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      tool: [],
      category: [],
      model: [],
      productList: [],
      activeType: 'doc',
      categoryOptions: [],
      search: '',
      // toolOptions: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    if (this.$parent.activeCategory) {
      this.category.push(this.$parent.activeCategory);
      this.$emit('chooseCategory', '')
    }
    this.fetchTools();
    this.getCategories();
  },

	watch: {
    currency: function() {
      this.filterProducts();
    }
	},
  methods: {
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
          .then((res) => {
            const categories = res.data.payload;
            let parentCategories = categories.filter((cat) => !cat.parent_id);
            parentCategories = parentCategories.map((parent) => {
              return {
                ...parent,
                child: categories.filter(({parent_id}) => parent.id === parent_id)
              }
            });
            parentCategories = parentCategories.sort((a, b) => a.id - b.id);
            this.categoryOptions = parentCategories;

            this.filterProducts();
          })
          .catch(() => {

          })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
    changeType(type) {
      this.activeType = type;
      this.filterProducts();
    },
    filterProducts() {
      console.log(this.tool, this.category);
      const params = new URLSearchParams();

      if (this.tool.length) {
        params.append('tool_id', this.tool.map(({id}) => id).join(','));
      }

      if (this.category.length) {
        params.append('category_id', this.category.map(({id}) => id).join(','));
      }
      let search;
      if (this.search) {
        search = '&search=' + this.search;
      } else {
        search = ''
      }
     

			this.$http.get(process.env.VUE_APP_API + `products/catalog?${params}` + search)
			.then((res) => {
				this.productList = res.data.payload;
			})
			.catch(() => {

			})
    },
    ...mapActions('tools', ['fetchTools']), // Map the 'incrementAsync' action
  },
  computed: {
    ...mapGetters('tools', {toolOptions: 'tools'})
  }
}
</script>