<template>
	<div class="menu">
		<ul class="nav">
		<li class="nav__item" @click="scrollToSection('features')">
			<div class="desc">OUR FEATURES</div>
		</li>
		<li class="nav__item" @click="scrollToSection('categories')">
			<div class="desc">CATEGORIES</div>
		</li>
		<li class="nav__item" @click="scrollToSection('how-it-works')">
			<div class="desc">HOW IT WORKS</div>
		</li>
		<li class="nav__item" @click="scrollToSection('contact')">
			<div class="desc">CONTACT US</div>
		</li>
		</ul>
		<div class="header-profile header-profile-mob" v-if="$parent.isAuth">
			<div class="cart" @click="$parent.openBuyModal">
				<img class="img" src="./../images/cart.svg"/>
			</div>
			<router-link class="button blue" to="/profile/main">My account</router-link>          
        </div>
		<div v-else>
			<div  class="button modal-open" @click="$emit('openSignInModal')">
				<span>Log In</span>
			</div>
		</div>
		<div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
			<select class="select currency-select" v-model="curr"
			@change="selectCurr()"
			>
			<option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
			</select>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Menu',
	props: ['currencyCode', 'currencyValue'],
	data: function() {
		return {
			curr: ''
		}
	},
	computed: {
		currOptions() {
			return this.$parent.currencyOptions.map(({code}) => code);
		}
	},
	watch: {
		currencyCode: function (newValue) {
			this.curr = newValue
		}
	},
	mounted() {
		this.curr = this.currencyCode
	},
	methods: {
		openSignInModal() {
			this.$emit('openSignInModal')
		},
		openSignUpModal() {
			this.$emit('openSignUpModal')
		},
		scrollToSection(section) {
			this.$parent.closeMenu();
			this.$emit('scrollToSection', section)
		},
		selectCurr() {
			this.changeCurrency(this.curr)
			localStorage.setItem("currency", this.curr);
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
	},
}
</script>
