<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="back-link-container">
          <router-link to="/product-list" class="back-link desc link">Back to marketplace</router-link>
        </div>
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <img v-if="productPageItem.product_type == 'image'" :src="$parent.imgDomain + productPageItem.doc_url" class="img"/>
                    <div v-else class="doc-img">
                      <img src='./../images/doc.svg' class="img"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="top">
                    <div class="left">
                      <div class="title">{{productPageItem.title}}</div>
                      <div class="info">
                        <div class="item-tag" v-if="tag">
                          <div :class="['desc', {'green': tag.id == 2}, {'oragne': tag.id == 3}, {'blue': tag.id == 4}]">{{tag.title}}</div>
                        </div>
                        <div class="desc" v-if="productPageItem.description" v-html="productPageItem.description"></div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="price">
                        <div class="price-title title">Price:</div>
                        <div class="price-value title">
                          {{productPageItem.price}}
                          <span class="currency">{{currency}}</span>
                        </div>
                      </div>
                      <button class="button blue" @click="openBuyModal(productPageItem)">Add to cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
export default {
  name: 'ProductPage',
  props: ['currency', 'productPageItem'],
  components: {
  },
  data: function() {
    return {
      tag: '',
    }
  },
	watch: {
    currency: function() {
      const id = this.$route.params.id;
      this.$emit('getProductPageContent', id);
    },
    categories: function(newVal) {
      let tag = newVal.find(cat => cat.id ==  this.productPageItem.category_id);
			this.tag = tag;
    },
    productPageItem: function(newVal) {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        let categories = res.data.payload;
        let tag;
        tag = categories.find(cat => cat.id ==  newVal.category_id);
        this.tag = tag;
        
      })
      .catch(() => {
        
      })
    }
	},
  created() {
    
  },
  computed: {
    keywords() {
      return this.productPageItem.keywords.split(",");
    },
  },
  
  mounted() {
    this.$emit('getProductPageContent',this.$route.params.id);
    this.$http.get(process.env.VUE_APP_API + 'categories')
		.then((res) => {
			let categories = res.data.payload;
      let tag;
      tag = categories.find(cat => cat.id ==  this.productPageItem.category_id);
      this.tag = tag;
			
		})
		.catch(() => {
			
		})
  },
  methods: {
		openBuyModal(item) {
			this.$emit('openBuyModal', item)
		}
  }
}
</script>