<template>
  <div class="modal buy-modal">
    <div class="overlay"  @click="$parent.closeBuyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeBuyModal" src="./../images/close.svg"/>
      <div class="container">
        <div class="form-wrapper">
          <div class="form-fields">
            <div class="title">CART</div>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>PREVIEW/NAME</div>
                    </th>
                    <th>
                      <div>PRICE</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in cartContents" :key="item.id">
                    <td>
                      <div class="flex">
                        <div @click="removeFromCart(item)" class="bin">
                          <img src="./../images/bin.svg" class="img"/>
                        </div>
                        <div class="preview">
                          <img v-if="item.item.product_type == 'image'" :src="$parent.imgDomain + item.item.doc_url" class="img"/>
                          <div class="doc-img" v-else>
                            <img src='./../images/doc.svg' class="img"/>
                          </div>
                        </div>
                        <div class="desc">{{item.item.title}}</div>
                      </div>
                    </td>
                    <td>
                      {{item.item.price}} <span>{{$parent.currency}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="desc clear-cart" @click="$parent.crealCart">Clear cart</div>
                    </td>
                    <td><div class="total-desc">Total:</div> {{totalPrice}} <span>{{$parent.currency}}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="chekbox-container">
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="checkbox" name="terms" v-model="terms"/>
                  <div class="checkbox"></div>
                  <span class="title">I agree with </span>
                  <a v-if="textPageList" @click="$parent.goToPrivacy()" class="title"> {{ textPageList[1].title }}</a>
                  <span class="title"> and </span>
                  <a v-if="textPageList" @click="$parent.goToTerms()" class="title"> {{ textPageList[0].title }}</a>
                </div>
              </label>
            </div>
            <div class="bottom">
              <div class="button dark" @click="$parent.closeBuyModal">Continue shopping</div>
              <button :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="buyProduct">Buy</button>
            </div>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyModal',
  props: ['cartContents', 'totalPrice'],
  components: {
  },
  data: function() {
    return {
      terms: false,
      textPageList: ''
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'static-pages')
    .then((res) => {
      this.textPageList = res.data.payload
    })
    .catch(() => {
        
    })
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
       this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    buyProduct() {
      this.$parent.buyProduct();
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', item)
    }
  }
}
</script>