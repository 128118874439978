<template>
  <main class="main page-inside text-page faq-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <transition name="fade">
          <div class="wrapper">
            <div class="title">Frequently Asked Questions</div>
          </div>
        </transition>
      </div>
      <div class="section text-section">
        <div class="wrapper">
          <div class="item" v-for="item in faq" :key="item.id">
            <div class="title small">{{item.question}}</div>
            <div class="desc" v-html="item.answer"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: [],
  components: {
  },
  data: function() {
    return {
      faq: []
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
          this.faq = res.data.payload;
      })
      .catch(() => {
        
      })
  },
  methods: {
    
  }
}
</script>